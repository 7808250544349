import { useLocation } from "react-router-dom";
import Sidebar from "../shared/sidebar/Sidebar";
import "./AdminDashboard.scss";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faPlus } from "@fortawesome/free-solid-svg-icons";
import Navbar from "../layout/navbar/Navbar";

function AdminDashboard() {
  const { auth } = useSelector((state) => state.reducer);
  console.log("auth below", auth);

  // const {state}=useLocation()
  // const {authHeader}=state
  // if(click){
  // classNameStyle={{width:'100%'}}
  // }

  return (
    <>
      <Navbar />
      <div className="outer-dashboard">
        <div className="row">
          <div className="col-md-12">
            <div className="right-dashboard">
              <div className="top-header">
                <div className="vehicles-data">
                  <div className="vehicle-list">
                    <span className="text">All Vehicles</span>
                    <span className="numbers">16</span>
                  </div>
                  <div className="vehicle-list">
                    <span className="text">Available</span>
                    <span className="numbers">16</span>
                  </div>
                  <div className="vehicle-list">
                    <span className="text">On Job</span>
                    <span className="numbers">16</span>
                  </div>
                  <div className="vehicle-list">
                    <span className="text">POB</span>
                    <span className="numbers">16</span>
                  </div>
                  <div className="vehicle-list">
                    <span className="text">Soon to clear</span>
                    <span className="numbers">16</span>
                  </div>
                  <div className="vehicle-list">
                    <span className="text">Required Vehicles</span>
                    <span className="numbers">16</span>
                  </div>
                </div>
              </div>
              <div className="map-area">
                <iframe
                  title="iframe-title"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d425289.3917375842!2d72.80590899341999!3d33.61637228136273!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38dfbfd07891722f%3A0x6059515c3bdb02b6!2sIslamabad%2C%20Islamabad%20Capital%20Territory%2C%20Pakistan!5e0!3m2!1sen!2s!4v1653592972695!5m2!1sen!2s"
                  width="100%"
                  height=""
                  style={{ border: 0 }}
                  referrerPolicy="no-referrer-when-downgrade"
                  loading="lazy"
                  allowFullScreen
                ></iframe>
              </div>
              <div className="table-area">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Pickup Time</th>
                        <th scope="col">Pickup</th>
                        <th scope="col">Destination</th>
                        <th scope="col">Priority</th>
                        <th scope="col">Price</th>
                        <th scope="col">Account Cash</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="on-job">
                        <td>Bryant Lemke</td>
                        <td>01/07/2022 7:54 AM</td>
                        <td>33422 Mitchell Hill Suite 778, MD</td>
                        <td>472 Eugenia Turnpike Apt. 188, CT</td>
                        <td>01</td>
                        <td>£85.65</td>
                        <td>£85.65</td>
                        <td>ON JOB</td>
                      </tr>
                      <tr className="available-ride">
                        <td>Bryant Lemke</td>
                        <td>01/07/2022 7:54 AM</td>
                        <td>33422 Mitchell Hill Suite 778, MD</td>
                        <td>472 Eugenia Turnpike Apt. 188, CT</td>
                        <td>01</td>
                        <td>£85.65</td>
                        <td>£85.65</td>
                        <td>ON JOB</td>
                      </tr>
                      <tr className="pob">
                        <td>Bryant Lemke</td>
                        <td>01/07/2022 7:54 AM</td>
                        <td>33422 Mitchell Hill Suite 778, MD</td>
                        <td>472 Eugenia Turnpike Apt. 188, CT</td>
                        <td>01</td>
                        <td>£85.65</td>
                        <td>£85.65</td>
                        <td>ON JOB</td>
                      </tr>
                      <tr className="required">
                        <td>Bryant Lemke</td>
                        <td>01/07/2022 7:54 AM</td>
                        <td>33422 Mitchell Hill Suite 778, MD</td>
                        <td>472 Eugenia Turnpike Apt. 188, CT</td>
                        <td>01</td>
                        <td>£85.65</td>
                        <td>£85.65</td>
                        <td>ON JOB</td>
                      </tr>
                      <tr className="on-job">
                        <td>Bryant Lemke</td>
                        <td>01/07/2022 7:54 AM</td>
                        <td>33422 Mitchell Hill Suite 778, MD</td>
                        <td>472 Eugenia Turnpike Apt. 188, CT</td>
                        <td>01</td>
                        <td>£85.65</td>
                        <td>£85.65</td>
                        <td>ON JOB</td>
                      </tr>
                      <tr className="on-job">
                        <td>Bryant Lemke</td>
                        <td>01/07/2022 7:54 AM</td>
                        <td>33422 Mitchell Hill Suite 778, MD</td>
                        <td>472 Eugenia Turnpike Apt. 188, CT</td>
                        <td>01</td>
                        <td>£85.65</td>
                        <td>£85.65</td>
                        <td>ON JOB</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminDashboard;
