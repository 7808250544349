import './AdminLogin.scss';
import {Link,Route, BrowserRouter as Router, useNavigate} from 'react-router-dom'
import { ChangeEvent, MouseEventHandler, useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import {useSelector,useDispatch} from 'react-redux';
import { setAuth, setAuthHeader } from '../../redux/actions';


function AdminLogin(){

  const dispatch = useDispatch()
  const{auth}= useSelector((state ) => state.reducer)

  const [loginInfo, setLoginInfo] = useState({username:'', password:''});

  const inputClickHandler = (e ) =>{

    const {name, value} = e.target;

    setLoginInfo({...loginInfo, [name]:value})

  }

  const navigate = useNavigate();

  const loginClickHandler = async () =>{
  
   
    axios.post("https://stgapi.cabscannerhq.com/api/authenticate",
    {
      "username":loginInfo.username,
      "rememberMe": true,
      "password":loginInfo.password
    })
    .then(async function (response) {
      localStorage.setItem("auth","true")
      localStorage.setItem("header",''+response.headers.authorization)
      // dispatch(setAuth(true))
      dispatch(setAuthHeader(''+response.headers.authorization))
     
        navigate('/admin-dashboard',{state:{authHeader:response.headers.authorization}});
        navigate("/airport-geofencing")
     
    })
    .catch(function (error) {
      console.log('erro', error);
      alert("Incorrect username or passwrod")
     
    });

  
  }

    return(
      <>
      <div className="login-page">
  <div className="login-box">
    <div className="inner-login">
      <div className="logo-section">
        <img src="../../assets/images/CS_logo_horizontal.svg" alt="cabscanner logo" />
      </div>
      <div className="text">
        Please enter you credentials to Sign In to your account.
      </div>
      <div className="form-area">
        <form action="">
          <div className="form-group">
            <input type="text" className="form-control app-inputfield" name="username" value={loginInfo.username} id="emailId" onChange={inputClickHandler} />
          </div>
          <div className="form-group">
            <input type="password" className="form-control app-inputfield" name="password" value={loginInfo.password} id="password" onChange={inputClickHandler}/>
          </div>
          <div className="form-group">
            <button className="primary-radius-btn w-100" type="button" onClick={loginClickHandler}>SIGN IN</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
      </>
    )
}

export default AdminLogin;