import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useEffect, useState } from "react";
import Sidebar from "../shared/sidebar/Sidebar";
import { faBars, faPlus } from "@fortawesome/free-solid-svg-icons";
import "./AdminBooking.scss";
import Navbar from "../layout/navbar/Navbar";
function AdminBooking() {
  const header = localStorage.getItem("header");
  const [activeBookingStatus, setActiveBookingStatus] = useState(true);
  const [scheduledBookingStatus, setScheduledBookingStatus] = useState(false);
  const [historyRideStatus, setHistoryRideStatus] = useState(false);
  const [rideStatusClickCheck, setRideStatusClickCheck] = useState(false);
  const [filteredRideRequests, setFilteredRideRequests] = useState([]);
  const [inputSearchValue, setInputSearchValue] = useState("");
  const [searchedResult, setSearchedResult] = useState([]);

  const [rideRuquests, setRideRequests] = useState([]);
  // var rideRuquests = []
  useEffect(() => {
    axios
      .get("https://stgapi.cabscannerhq.com/api/ride-requests", {
        headers: { Authorization: header },
      })
      .then((response) => {
        setRideRequests(response.data);
        rideStatusClickCheck
          ? setRideStatusClickCheck(false)
          : setRideStatusClickCheck(true);
      });
  }, []);

  useEffect(() => {
    const copyFilterRequests = rideRuquests.filter((Ride) => {
      if (!inputSearchValue) {
        if (activeBookingStatus) {
          if (
            Ride.rideProgressStatus == "ACCEPTED" ||
            Ride.rideProgressStatus == "ARRIVED" ||
            Ride.rideProgressStatus == "POB" ||
            Ride.rideProgressStatus == "COMPLETE" ||
            Ride.rideProgressStatus == "DESTINATION_REACHED"
          ) {
            return Ride;
          }
        } else if (scheduledBookingStatus) {
          if (
            Ride.rideProgressStatus == "PENDING" ||
            Ride.rideProgressStatus == "CONFIRMED" ||
            Ride.rideProgressStatus == "SCHEDULED" ||
            Ride.rideProgressStatus == "BOOKEDNOTACTIVE" ||
            Ride.rideProgressStatus == "BOOKEDACTIVE"
          ) {
            return Ride;
          }
        } else if (historyRideStatus) {
          if (
            Ride.rideProgressStatus == "PAYMENT_PROCESSED" ||
            Ride.rideProgressStatus == "CANCELED" ||
            Ride.rideProgressStatus == "DRIVER_NOT_FOUND" ||
            Ride.rideProgressStatus == "NO_SHOW" ||
            Ride.rideProgressStatus == "NO_FARE"
          ) {
            return Ride;
          }
        }
      } else {
        if (activeBookingStatus) {
          if (
            (Ride.rideProgressStatus == "ACCEPTED" ||
              Ride.rideProgressStatus == "ARRIVED" ||
              Ride.rideProgressStatus == "POB" ||
              Ride.rideProgressStatus == "COMPLETE" ||
              Ride.rideProgressStatus == "DESTINATION_REACHED") &&
            Ride.customerName
              .toLowerCase()
              .startsWith(inputSearchValue.toLowerCase())
          ) {
            return Ride;
          }
        } else if (scheduledBookingStatus) {
          if (
            (Ride.rideProgressStatus == "PENDING" ||
              Ride.rideProgressStatus == "CONFIRMED" ||
              Ride.rideProgressStatus == "SCHEDULED" ||
              Ride.rideProgressStatus == "BOOKEDNOTACTIVE" ||
              Ride.rideProgressStatus == "BOOKEDACTIVE") &&
            Ride.customerName
              .toLowerCase()
              .startsWith(inputSearchValue.toLowerCase())
          ) {
            return Ride;
          }
        } else if (historyRideStatus) {
          if (
            (Ride.rideProgressStatus == "PAYMENT_PROCESSED" ||
              Ride.rideProgressStatus == "CANCELED" ||
              Ride.rideProgressStatus == "DRIVER_NOT_FOUND" ||
              Ride.rideProgressStatus == "NO_SHOW" ||
              Ride.rideProgressStatus == "NO_FARE") &&
            Ride.customerName
              .toLowerCase()
              .startsWith(inputSearchValue.toLowerCase())
          ) {
            return Ride;
          }
        }
      }
    });
    setFilteredRideRequests(copyFilterRequests);
    setSearchedResult(copyFilterRequests);
  }, [rideStatusClickCheck]);

  const onInputChangeHandler = (e) => {
    setInputSearchValue(e.target.value);
    if (e.target.value) {
      const copyySearchedResult = searchedResult.filter((SearchedItem) => {
        if (
          SearchedItem.customerName
            .toLowerCase()
            .startsWith(e.target.value.toLowerCase())
        ) {
          return SearchedItem;
        }
      });

      setFilteredRideRequests(copyySearchedResult);
    } else {
      rideStatusClickCheck
        ? setRideStatusClickCheck(false)
        : setRideStatusClickCheck(true);
    }
  };

  return (
    <>
      <Navbar />
      <div className="outer-dashboard">
        <div className="row">
          <div className="col-md-12">
            <div className="right-dashboard">
              <div className="bookings-header d-flex justify-content-between align-items-center">
                <div className="booking-text d-flex flex-column justify-content-center">
                  <div className="booking-img d-flex align-items-center">
                    <img
                      src="../../../assets/images/bookings-active.svg"
                      alt=""
                    />
                    <span className="heading ms-2">Bookings</span>
                  </div>
                  <div className="sub-heading">
                    View and Create New Bookings
                  </div>
                </div>
                <div className="add-booking-btn">
                  <button
                    className="primary-radius-btn"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop"
                  >
                    + Add new
                  </button>
                </div>
              </div>
              <div className="top-header">
                <div className="bookings-status">
                  <div
                    className="bookings-status_text"
                    onClick={() => {
                      setActiveBookingStatus(true);
                      setScheduledBookingStatus(false);
                      setHistoryRideStatus(false);
                      rideStatusClickCheck
                        ? setRideStatusClickCheck(false)
                        : setRideStatusClickCheck(true);
                    }}
                  >
                    <span
                      className={activeBookingStatus ? "text active" : "text"}
                    >
                      <a>Active Bookings</a>
                    </span>
                  </div>
                  <div
                    className="bookings-status_text"
                    onClick={() => {
                      setActiveBookingStatus(false);
                      setScheduledBookingStatus(true);
                      setHistoryRideStatus(false);
                      rideStatusClickCheck
                        ? setRideStatusClickCheck(false)
                        : setRideStatusClickCheck(true);
                    }}
                  >
                    <span
                      className={
                        scheduledBookingStatus ? "text active" : "text"
                      }
                    >
                      <a>Scheduled Bookings</a>
                    </span>
                  </div>
                  <div
                    className="bookings-status_text"
                    onClick={() => {
                      setActiveBookingStatus(false);
                      setScheduledBookingStatus(false);
                      setHistoryRideStatus(true);
                      rideStatusClickCheck
                        ? setRideStatusClickCheck(false)
                        : setRideStatusClickCheck(true);
                    }}
                  >
                    <span
                      className={historyRideStatus ? "text active" : "text"}
                    >
                      <a>History Rides</a>
                    </span>
                  </div>
                </div>
                <div className="bookings-filters">
                  <div className="dropdown">
                    <a
                      className=""
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span className="month">
                        <img
                          src="../../../assets/images/Icon ionic-md-calendar.svg"
                          alt="calendar icon"
                          height="13"
                        />
                        &nbsp;Feb 1, 2022 - Feb 20, 2022
                      </span>
                      <span className="icon">
                        <FontAwesomeIcon icon={faAngleDown} />
                      </span>
                    </a>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li>
                        <a className="dropdown-item" href="#">
                          Action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Another action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Something else here
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="dropdown">
                    <a
                      className=""
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span className="month">
                        <img
                          src="../../../assets/images/Icon material-photo-filter.svg"
                          alt="price filter"
                          height="13"
                        />
                        &nbsp;Highest Price First
                      </span>
                      <span className="icon">
                        <FontAwesomeIcon icon={faAngleDown} />
                      </span>
                    </a>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li>
                        <a className="dropdown-item" href="#">
                          Action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Another action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Something else here
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="dropdown">
                    <a
                      className=""
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span className="month">
                        <img
                          src="../../../assets/images/Group 10566.svg"
                          alt=""
                          height="13"
                        />
                        &nbsp;On Job
                      </span>
                      <span className="icon">
                        <FontAwesomeIcon icon={faAngleDown} />
                      </span>
                    </a>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li>
                        <a className="dropdown-item" href="#">
                          Action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Another action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Something else here
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="search-area">
                    <form action="">
                      <div className="form-group">
                        <input
                          type="search"
                          className="form-control app-inputfield"
                          placeholder="search here...."
                          value={inputSearchValue}
                          onChange={onInputChangeHandler}
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="table-area">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Pickup Time</th>
                        <th scope="col">Pickup</th>
                        <th scope="col">Destination</th>
                        <th scope="col">Priority</th>
                        <th scope="col">Price</th>
                        <th scope="col">Account Cash</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredRideRequests.map((ride, index) => {
                        return (
                          <tr className="on-job">
                            <td>{ride.customerName}</td>
                            {ride.rideSegments.map((rdSegment) => (
                              <>
                                <td>
                                  {new Date(
                                    rdSegment.requestedPickupTime
                                  ).toLocaleDateString() +
                                    " " +
                                    new Date(rdSegment.requestedPickupTime)
                                      .toLocaleTimeString()
                                      .split(":")
                                      .slice(0, 2)}
                                </td>
                                <td>{rdSegment.requestedPickupLabel}</td>
                                <td>{rdSegment.requestedDropOffLabel}</td>
                              </>
                            ))}

                            <td>{}</td>
                            <td>{}</td>
                            <td>{}</td>
                            <td>{ride.rideProgressStatus}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Add New Booking Modal --> */}
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <div>
                <div className="modal-title" id="staticBackdropLabel">
                  Add New Booking
                </div>
                <small>Please Enter Phone Number To Create New Booking.</small>
              </div>
              <div
                className="cross-img"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <a>
                  <img
                    src="../../../assets/images/cross.svg"
                    alt="close modal"
                  />
                </a>
              </div>
            </div>
            <div className="modal-body">
              <div className="inner-body">
                <form action="">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control app-inputfield"
                      name=""
                      id=""
                    />
                  </div>
                  <div className="form-group mt-3">
                    <button className="primary-radius-btn w-100">Next</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminBooking;
