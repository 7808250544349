import Navbar from "../layout/navbar/Navbar";
import Sidebar from "../shared/sidebar/Sidebar";
import "./SystemConfigForm.scss";
function SystemConfigForm() {
  return (
    <>
      <Navbar />
      <div className="outer-dashboard">
        <div className="row">
          <div className="col-md-12">
            <div className="right-dashboard">
              <div className="bookings-header d-flex justify-content-between align-items-center">
                <div className="booking-text d-flex flex-column justify-content-center">
                  <div className="booking-img d-flex align-items-center">
                    <img
                      src="../../../assets/images/bookings-active.svg"
                      alt=""
                    />
                    <span className="heading ms-2">System Configuration</span>
                  </div>
                  <div className="sub-heading">
                    Create/Edit System Configuration
                  </div>
                </div>
                <div className="add-booking-btn">
                  <button className="cancel-radius-btn" type="button">
                    Cancel
                  </button>
                </div>
              </div>
              <div className="config-pages-forms">
                <div className="personal-info-form">
                  <div className="form-area">
                    <div className="heading">NEW System Configuration</div>
                    <form action="">
                      <div className="row personal-form-area">
                        <div className="col-md-4 form-group">
                          <label htmlFor="integrationName">Name</label>
                          <input
                            type="text"
                            className="form-control app-inputfield"
                            name=""
                            id="integrationName"
                            placeholder="Name"
                          />
                        </div>
                        <div className="col-md-4 form-group">
                          <label htmlFor="vlaue">Value</label>
                          <input
                            type="text"
                            className="form-control app-inputfield"
                            name=""
                            id="vlaue"
                            placeholder=""
                          />
                        </div>
                        <div className="col-md-4 form-group">
                          <label htmlFor="SelectConnectorType">
                            Select Connector Type
                          </label>
                          <select
                            name=""
                            id="SelectConnectorType"
                            className="form-select select-option"
                          >
                            <option value="">Select Connector Type</option>
                          </select>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="form-footer d-flex justify-content-end mt-3 border-top">
                  <div className="form-group">
                    <button className="primary-radius-btn px-5" type="submit">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SystemConfigForm;
