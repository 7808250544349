import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCoffee,
  faAngleDown,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";

import "./Sidebar.scss";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setAnaLyticsDropdown, setAuth } from "../../../redux/actions";
import { useSelector } from "react-redux";
function Sidebar() {
  const { auth, analyticsDropdown } = useSelector((state) => state.reducer);
  console.log("Sidebar auth", auth);
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const onDropdownHandler = (e) => {
    dispatch(setAuth(true));
    dispatch(setAnaLyticsDropdown(false));
  };

  const onSubDropdownHandler = (e) => {
    dispatch(setAnaLyticsDropdown(true));
    dispatch(setAuth(false));
  };

  return (
    <>
      <div className="sidebar">
        <div className="sidebar-list">
          {/* <Link className="list-items active" to="/admin-dashboard">
            <div className="icon">
              <img src="../../assets/images/dash.svg" alt="dash icon" />
            </div>
            <div className="text">Dashboard</div>
          </Link>
          <Link className="list-items" to="/admin-booking">
            <div className="icon">
              <img src="../../assets/images/bookings.svg" alt="bookings icon" />
            </div>
            <div className="text">Bookings</div>
          </Link>
          <Link className="list-items" to="/admin-booking">
            <div className="icon">
              <img
                src="../../assets/images/mng-bookings.svg"
                alt="manage bookings icon"
              />
            </div>
            <div className="text">Manage Bookings</div>
          </Link>
          <Link className="list-items" to="/admin-booking1">
            <div className="icon">
              <img src="../../assets/images/invite.svg" alt="invite people" />
            </div>
            <div className="text">Invite People</div>
          </Link> */}

          <div className="list-items d-block" to="/admin-booking1">
            <div
              className="d-flex align-items-center"
              data-bs-toggle="collapse"
              href="#collapseConfig"
              role="button"
              aria-expanded="false"
              aria-controls="collapseConfig"
            >
              <div className="icon">
                <img src="../../assets/images/config.svg" alt="settings icon" />
              </div>
              <div className="w-100 d-flex justify-content-between">
                <div className="text">Configuration</div>
                <div className="drop-icon">
                  <FontAwesomeIcon icon={faPlus} />
                </div>
              </div>
            </div>
            <div className="collapse" id="collapseConfig">
              {/* <li><Link className="dropdown-item" to="/analytics/registration">Analytics-Registration</Link></li> */}
              <div className="innerlinks">
                <Link className="dropdown-item" to="/service-provider">
                  Service Provider
                </Link>
              </div>
              <div className="innerlinks">
                <a className="dropdown-item" href="/airport-geofencing">
                  Airport Geofencing
                  </a>
              </div>
              <div className="innerlinks">
                <Link className="dropdown-item" to="/fares-config">
                  Fare
                </Link>
              </div>
              {/* <div className="innerlinks">
                <Link className="dropdown-item" to="/facilities-defination">
                  Facilities Defination
                </Link>
              </div>
              <div className="innerlinks">
                <Link className="dropdown-item" to="/luguage-defination">
                  Luguage Defination
                </Link>
              </div>
              <div className="innerlinks">
                <Link className="dropdown-item" to="/promotion-defination">
                  Promotion Defination
                </Link>
              </div>
              <div className="innerlinks">
                <Link className="dropdown-item" to="/ridetype-defination">
                  Ride Type Defination
                </Link>
              </div>
              <div className="innerlinks">
                <Link className="dropdown-item" to="/service-area">
                  Service Area
                </Link>
              </div>
              <div className="innerlinks">
                <Link className="dropdown-item" to="/shift-defination">
                  Shift Defination
                </Link>
              </div>
              <div className="innerlinks">
                <Link className="dropdown-item" to="/vehicletype-defination">
                  Vehicle Type Defination
                </Link>
              </div>
              <div className="innerlinks">
                <Link className="dropdown-item" to="/integrations">
                  Integration
                </Link>
              </div>
              <div className="innerlinks">
                <Link className="dropdown-item" to="/activate-user">
                  Activate User
                </Link>
              </div>
              <div className="innerlinks">
                <Link className="dropdown-item" to="/telephony">
                  Telephony
                </Link>
              </div> */}
              <div className="innerlinks">
                <Link className="dropdown-item" to="/system-config">
                  System Configuration
                </Link>
              </div>
              <div>
                {/* <div className="list-items dropdown" to="/admin-booking1">
           <div className="icon">
             <img src="../../assets/images/config.svg" alt="settings icon" />
           </div>
         <div className={analyticsDropdown?"text dropdown-toggle show":"text dropdown-toggle"} id="navbarDropdown1" role="button" data-bs-toggle="dropdown"
          aria-expanded="true" onClick={onSubDropdownHandler}>
           <div>Analytics</div>
           <div className="drop-icon"><FontAwesomeIcon icon={faAngleDown} /></div>
        </div>

        <ul className={analyticsDropdown?"dropdown-menu show":"dropdown-menu"} aria-labelledby="navbarDropdown1" >
        <li><Link className="dropdown-item" to="/analytics/registration">Registration</Link></li>
        </ul>
      </div> */}
              </div>
            </div>
          </div>

          <div className="list-items d-block" to="/admin-booking1">
            <div
              className="d-flex align-items-center"
              data-bs-toggle="collapse"
              href="#collapseAnalytics"
              role="button"
              aria-expanded="false"
              aria-controls="collapseAnalytics"
            >
              <div className="icon">
                <img
                  src="../../assets/images/analysis.png"
                  alt="settings icon"
                />
              </div>
              <div className="w-100 d-flex justify-content-between">
                <div className="text">Analytics</div>
                <div className="drop-icon">
                  <FontAwesomeIcon icon={faPlus} />
                </div>
              </div>
            </div>
            <div className="collapse" id="collapseAnalytics">
              <div className="innerlinks">
                <Link className="dropdown-item" to="/analytics/registration">
                  Registration
                </Link>
              </div>
              <div className="innerlinks">
                <Link className="dropdown-item" to="/analytics/ride-history">
                  RideHistory
                </Link>
              </div>
              <div className="innerlinks">
                <Link className="dropdown-item" to="/analytics/payments">
                  Payments
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
