import {
  faEye,
  faPencil,
  faTrashCan,
  faArrowsRotate,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Sidebar from "../shared/sidebar/Sidebar";
import Navbar from "../layout/navbar/Navbar";

function PromotionDefinition() {
  return (
    <>
    <Navbar />
      <div className="outer-dashboard">
        <div className="row">
          <div className="col-md-12">
            <div className="right-dashboard">
              <div className="bookings-header d-flex justify-content-between align-items-center">
                <div className="booking-text d-flex flex-column justify-content-center">
                  <div className="booking-img d-flex align-items-center">
                    <img
                      src="../../../assets/images/Icon feather-settings.svg"
                      alt=""
                    />
                    <span className="heading ms-2">Promotion Definition</span>
                  </div>
                  <div className="sub-heading">
                    Create/View/Edit New Promotion Definition
                  </div>
                </div>
                <div className="add-booking-btn">
                  <button className="primary-radius-btn" type="button">
                    + CREATE PROMOTION
                  </button>
                </div>
              </div>
              <div className="all-configuration-styles">
                <div className="section-activity-area">
                  <div className="btns">
                    <button className="activity-btns">
                      <FontAwesomeIcon icon={faEye} /> View
                    </button>
                    <button className="activity-btns">
                      <FontAwesomeIcon icon={faPencil} /> Edit
                    </button>
                    <button className="activity-btns">
                      <FontAwesomeIcon icon={faTrashCan} /> Delete
                    </button>
                    <button className="activity-btns">
                      <FontAwesomeIcon icon={faArrowsRotate} /> Refresh
                    </button>
                  </div>
                  <div className="search-area">
                    <form action="">
                      <div className="form-group">
                        <input
                          type="search"
                          className="form-control app-inputfield"
                          name=""
                          id=""
                          placeholder="search here..."
                        />
                      </div>
                    </form>
                  </div>
                </div>
                <div className="table-area">
                  <div className="table-responsive">
                    <table className="table">
                      <thead className="position-sticky top-0">
                        <tr>
                          <th scope="col">Title</th>
                          <th scope="col">Best Value</th>
                          <th scope="col">Daily Start Time</th>
                          <th scope="col">Daily End Time</th>
                          <th scope="col">Max Discount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>TwinCity Distance Price</td>
                          <td>12</td>
                          <td>14:45</td>
                          <td>14:49</td>
                          <td>30%</td>
                        </tr>
                        <tr>
                          <td>TwinCity Distance Price</td>
                          <td>12</td>
                          <td>14:45</td>
                          <td>14:49</td>
                          <td>30%</td>
                        </tr>
                        <tr>
                          <td>TwinCity Distance Price</td>
                          <td>12</td>
                          <td>14:45</td>
                          <td>14:49</td>
                          <td>30%</td>
                        </tr>
                        <tr>
                          <td>TwinCity Distance Price</td>
                          <td>12</td>
                          <td>14:45</td>
                          <td>14:49</td>
                          <td>30%</td>
                        </tr>
                        <tr>
                          <td>TwinCity Distance Price</td>
                          <td>12</td>
                          <td>14:45</td>
                          <td>14:49</td>
                          <td>30%</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PromotionDefinition;
