import React from "react";
import Sidebar from "../../shared/sidebar/Sidebar";
import Iframe from "react-iframe";
import Navbar from "../../layout/navbar/Navbar";

function Payments() {
  return (
    <div style={{height: "100%"}} >
    <Navbar />
      <div className="outer-dashboard">
        <div className="row">
          <div className="col-md-12">
            <div className="right-dashboard">
              <div className="all-configuration-styles">
                <div className="table-area" style={{height: "100%"}}>
                  <Iframe
                    url="https://analytics.cabscannerhq.com/public/dashboard/55f63960-eba6-4163-bcf7-3b860a897cd3"
                    width="100%"
                    height="470px"
                    id="myId"
                    className="myClassname"
                    display="initial"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Payments;
