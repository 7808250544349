import React from "react";
import Iframe from "react-iframe";
import Navbar from "../../layout/navbar/Navbar";
import Sidebar from "../../shared/sidebar/Sidebar";

function Registration() {
  return (
    <div height="100%" style={{overflow:'hidden'}}>
      <Navbar />
      <div className="outer-dashboard">
        <div className="row">
          <div className="col-md-12">
            <div className="right-dashboard">
              <div className="all-configuration-styles">
                <div className="table-area" style={{height: "100%"}}>
                  <Iframe
                    url="https://analytics.cabscannerhq.com/public/dashboard/c250cd0c-4378-43ce-99d6-0a7e55353ba5"
                    width="100%"
                    height="470px"
                    id="myId"
                    className="myClassname"
                    display="initial"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Registration;
