export const SET_AUTH = "SET_AUTH"
export const SET_AUTH_HEADER = "SET_AUTH_HEADER"
export const SET_ANALYTICS_DROPDOWN = "SET_ANALYTICS_DROPDOWN"
export const SET_EDIT_CHECK = "SET_EDIT_CHECK"



export const setEditCheck = editCheck => dispatch => {

    dispatch({
        type: SET_EDIT_CHECK,
        payload: {
            editCheck
        }
    });
}


export const setAnaLyticsDropdown = analyticsDropdown => dispatch => {

    dispatch({
        type: SET_ANALYTICS_DROPDOWN,
        payload: {
            analyticsDropdown: analyticsDropdown
        }
    });
}


export const setAuth = auth => dispatch => {

    dispatch({
        type: SET_AUTH,
        payload: {
            auth: auth
        }
    });
}


export const setAuthHeader = header => dispatch => {
    dispatch({
        type:SET_AUTH_HEADER,
        payload:{
            header:header
        }
    })
}