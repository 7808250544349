import {
  faAngleDown,
  faArrowsRotate,
  faEye,
  faPencil,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Navbar from "../layout/navbar/Navbar";
import Sidebar from "../shared/sidebar/Sidebar";
import "./FaresConfig.scss";

function FaresConfig() {
  const AuthStr =
    "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhZG1pbiIsImF1dGgiOiJST0xFX0FETUlOLFJPTEVfQlVfQURNSU4sUk9MRV9CVV9VU0VSLFJPTEVfQ09SUE9SQVRFX0FETUlOLFJPTEVfQ09SUE9SQVRFX0NVU1RPTUVSLFJPTEVfQ09SUE9SQVRFX1VTRVIsUk9MRV9DVVNUT01FUixST0xFX0RJU1BBVENIX0FETUlOLFJPTEVfRElTUEFUQ0hfVVNFUixST0xFX0RSSVZFUixST0xFX1VTRVIiLCJleHAiOjE2NjA3MzM2MDN9.Nkq7Utcn--Q7uBK-59LUgAXlJ3mbjLGUITH7OjmdkWt6qjOWnWw64RoD2x73tzkJ5tVofCALYTC1TU8lixLiJg";
  useEffect(() => {
    axios
      .get("https://stgapi.cabscannerhq.com/api/system-configuration-keys", {
        headers: { Authorization: AuthStr },
      })
      .then((response) => {
        console.log("get response", response.data);
      })
      .catch((error) => {
        console.log("get error", error);
      });
  }, []);
  return (
    <>
      <Navbar />
      <div className="outer-dashboard">
        <div className="row">
          <div className="col-md-12">
            <div className="right-dashboard">
              <div className="bookings-header d-flex justify-content-between align-items-center">
                <div className="booking-text d-flex flex-column justify-content-center">
                  <div className="booking-img d-flex align-items-center">
                    <img
                      src="../../../assets/images/Icon feather-settings.svg"
                      alt=""
                    />
                    <span className="heading ms-2">Fares</span>
                  </div>
                  <div className="sub-heading">Fares Configurations</div>
                </div>
                <div className="add-booking-btn">
                  <button className="primary-radius-btn" type="button">
                    + CREATE NEW FARE
                  </button>
                </div>
              </div>
              <div className="all-configuration-styles">
                <div className="section-activity-area">
                  <div className="btns">
                    <button className="activity-btns">
                      <FontAwesomeIcon icon={faEye} /> View
                    </button>
                    <button className="activity-btns">
                      <FontAwesomeIcon icon={faPencil} /> Edit
                    </button>
                    <button className="activity-btns">
                      <FontAwesomeIcon icon={faTrashCan} /> Delete
                    </button>
                    <button className="activity-btns">
                      <FontAwesomeIcon icon={faArrowsRotate} /> Refresh
                    </button>
                  </div>
                  <div className="search-area">
                    <form action="">
                      <div className="form-group">
                        <input
                          type="search"
                          className="form-control app-inputfield"
                          name=""
                          id=""
                          placeholder="search here..."
                        />
                      </div>
                    </form>
                  </div>
                </div>
                <div className="table-area">
                  <div className="table-responsive">
                    <table className="table">
                      <thead className="position-sticky top-0">
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Unit</th>
                          <th scope="col">Wait Time Unit Price</th>
                          <th scope="col">Dead Speed</th>
                          <th scope="col">Max Journey Distance</th>
                          <th scope="col">Minimum Fare</th>
                          <th scope="col">Currency</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>TwinCity Distance Price</td>
                          <td>KM</td>
                          <td>02</td>
                          <td>10</td>
                          <td>32</td>
                          <td>10</td>
                          <td>Pounds</td>
                        </tr>
                        <tr>
                          <td>TwinCity Distance Price</td>
                          <td>KM</td>
                          <td>02</td>
                          <td>10</td>
                          <td>32</td>
                          <td>10</td>
                          <td>Pounds</td>
                        </tr>
                        <tr>
                          <td>TwinCity Distance Price</td>
                          <td>KM</td>
                          <td>02</td>
                          <td>10</td>
                          <td>32</td>
                          <td>10</td>
                          <td>Pounds</td>
                        </tr>
                        <tr>
                          <td>TwinCity Distance Price</td>
                          <td>KM</td>
                          <td>02</td>
                          <td>10</td>
                          <td>32</td>
                          <td>10</td>
                          <td>Pounds</td>
                        </tr>
                        <tr>
                          <td>TwinCity Distance Price</td>
                          <td>KM</td>
                          <td>02</td>
                          <td>10</td>
                          <td>32</td>
                          <td>10</td>
                          <td>Pounds</td>
                        </tr>
                        <tr>
                          <td>TwinCity Distance Price</td>
                          <td>KM</td>
                          <td>02</td>
                          <td>10</td>
                          <td>32</td>
                          <td>10</td>
                          <td>Pounds</td>
                        </tr>
                        <tr>
                          <td>TwinCity Distance Price</td>
                          <td>KM</td>
                          <td>02</td>
                          <td>10</td>
                          <td>32</td>
                          <td>10</td>
                          <td>Pounds</td>
                        </tr>
                        <tr>
                          <td>TwinCity Distance Price</td>
                          <td>KM</td>
                          <td>02</td>
                          <td>10</td>
                          <td>32</td>
                          <td>10</td>
                          <td>Pounds</td>
                        </tr>
                        <tr>
                          <td>TwinCity Distance Price</td>
                          <td>KM</td>
                          <td>02</td>
                          <td>10</td>
                          <td>32</td>
                          <td>10</td>
                          <td>Pounds</td>
                        </tr>
                        <tr>
                          <td>TwinCity Distance Price</td>
                          <td>KM</td>
                          <td>02</td>
                          <td>10</td>
                          <td>32</td>
                          <td>10</td>
                          <td>Pounds</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FaresConfig;
