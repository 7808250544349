import {
  faEye,
  faPencil,
  faTrashCan,
  faArrowsRotate,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import Sidebar from "../shared/sidebar/Sidebar";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./SystemConfig.scss";
import Navbar from "../layout/navbar/Navbar";

function SystemConfig() {
  const [systemConfigObjUpd, setSystemConfigObjUpd] = useState({});
  const [selectedRow, setSelectedRow] = useState(-1);
  const [checkClickRow, setCheckClickRow] = useState(false);

   const{editCheck}= useSelector((state ) => state.reducer)
  const header = localStorage.getItem("header");

  const navigate = useNavigate();

  const [systemConfigData, setSystemConfigData] = useState([
    { name: "", value: "", valueType: "" },
  ]);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SYSTEM_CONFIG_API}`, {
        headers: { Authorization: header },
      })
      .then((response) => {
        setSystemConfigData(response.data);
      })
      .catch((error) => {
        console.log("get error", error);
      });
  }, [editCheck]);

  const onEditHandler = () => {
    navigate("/system-config-edit", { state: { obj: systemConfigObjUpd } });
  };

  const onClickRow = (SystemConfigObjPassed, selectedRow) => {
    if (selectedRow !== undefined) {
      setSelectedRow(selectedRow);
    }
    setSystemConfigObjUpd(SystemConfigObjPassed);
    setCheckClickRow(true);
  };

  return (
    <>
      <Navbar />
      <div className="outer-dashboard">
        <div className="row">
          <div className="col-md-12">
            <div className="right-dashboard">
              <div className="bookings-header d-flex justify-content-between align-items-center">
                <div className="booking-text d-flex flex-column justify-content-center">
                  <div className="booking-img d-flex align-items-center">
                    <img
                      src="../../../assets/images/Icon feather-settings.svg"
                      alt=""
                    />
                    <span className="heading ms-2">System Configuration</span>
                  </div>
                  <div className="sub-heading">
                    Create/Edit New System Configurations
                  </div>
                </div>
                <div className="add-booking-btn">
                  <button className="primary-radius-btn" type="button">
                    + NEW CONFIGURATION
                  </button>
                </div>
              </div>
              <div className="all-configuration-styles">
                <div className="section-activity-area">
                  <div className="btns">
                    <button className="activity-btns" disabled={checkClickRow}>
                      <FontAwesomeIcon icon={faEye} /> View
                    </button>
                    <button
                      className="activity-btns"
                      onClick={onEditHandler}
                      disabled={!checkClickRow}
                    >
                      <FontAwesomeIcon icon={faPencil} /> Edit
                    </button>
                    <button className="activity-btns" disabled={!checkClickRow}>
                      <FontAwesomeIcon icon={faTrashCan} /> Delete
                    </button>
                    <button className="activity-btns" disabled={!checkClickRow}>
                      <FontAwesomeIcon icon={faArrowsRotate} /> Refresh
                    </button>
                  </div>
                  <div className="search-area d-none">
                    <form action="">
                      <div className="form-group">
                        <input
                          type="search"
                          className="form-control app-inputfield"
                          name=""
                          id=""
                          placeholder="search here..."
                        />
                      </div>
                    </form>
                  </div>
                </div>
                <div className="table-area">
                  <div className="table-responsive">
                    <table className="table">
                      <thead className="position-sticky top-0">
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        {systemConfigData.map((SystemConfigObj, index) => (
                          <tr
                            key={index}
                            id={index}
                            onClick={() => onClickRow(SystemConfigObj, index)}
                            className={
                              selectedRow === index ? "tableSelected" : ""
                            }
                          >
                            <td>{SystemConfigObj.name}</td>
                            {SystemConfigObj.name == "ride_search_radius" ? (
                              <td>{SystemConfigObj.value} m</td>
                            ) : SystemConfigObj.name ==
                              "driver_last_location_search_buffer_time" ? (
                              <td>{SystemConfigObj.value} s</td>
                            ) : (
                              <td>{SystemConfigObj.value}</td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default SystemConfig;
