import "./ServiceProvider.scss";
import axios from "axios";
import Sidebar from "../shared/sidebar/Sidebar";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import Navbar from "../layout/navbar/Navbar";

function ServiceProvider() {
  const header = localStorage.getItem("header");
  // below header is for API,s for Service Provider
  // const header =
  //   "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhd2Fpcy5pcWJhbEBtbW10ZWNobHRkLmNvbSIsImF1dGgiOiJST0xFX0NVU1RPTUVSLFJPTEVfVVNFUiIsImV4cCI6MTY2MzMxMTA2N30.dtsevBA7HkDTBIvTmt6-AQRnIdY54NQCLN4au4JawPKd8rJUZJVv5XO_otVlyO6-YvK3yS2OUP1uB_b-22_JAA";
  const [loading, setLoading] = useState(false);
  const [sProvider, setSProvider] = useState([]);
  const [checkSaveClick, setCheckSaveClick] = useState(false);
  const [pState, setPState] = useState({
    cardPayment: "",
    cashPayment: "",
    deliveryRideType: "",
    priorityRideType: "",
    privateRideType: "",
    sharedRideType: "",
    waypointsEnabled: "",
    enabled: "",
    walletPayment: "",
    commission: 0,
    misc: 0,
    fixedCommission: 0,
  });

  const onEditHandler = (providePassed) => {
    console.log("On Edit", providePassed);
    const {
      id,
      cardPayment,
      cashPayment,
      deliveryRideType,
      priorityRideType,
      privateRideType,
      sharedRideType,
      waypointsEnabled,
      enabled,
      walletPayment,
      commission,
      misc,
      fixedCommission,
    } = providePassed;
    const providerObj = {
      id,
      cardPayment,
      cashPayment,
      deliveryRideType,
      priorityRideType,
      privateRideType,
      sharedRideType,
      waypointsEnabled,
      enabled,
      walletPayment,
      commission,
      misc,
      fixedCommission,
    };
    setPState(providerObj);
  };

  const onChangeHandler = (e) => {
    if (e.target.type == "number") {
      setPState({
        ...pState,
        [e.target.name]: parseInt(e.target.value),
      });
    } else {
      setPState({
        ...pState,
        [e.target.name]: e.target.value == "true" ? true : false,
      });
    }
  };

  const onSaveChangesHandler = () => {
    console.log("pState", pState);
    axios
      .put(`${process.env.REACT_APP_SERVICE_PROVIDER_API}`, pState, {
        headers: { Authorization: header },
      })
      .then((response) => {
        checkSaveClick ? setCheckSaveClick(false) : setCheckSaveClick(true);
        setPState({});
        setLoading(false);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVICE_PROVIDER_API}`, {
        headers: { Authorization: header },
      })
      .then((response) => {
        console.log("service provider", response.data);
        setSProvider(response.data);
        setLoading(true);
      })
      .catch((error) => {
        console.log("get error", error);
      });
  }, [checkSaveClick]);

  return (
    <>
      <Navbar />
      <div className="outer-dashboard">
        <div className="row">
          <div className="col-md-12">
            <div className="right-dashboard">
              <div className="bookings-header d-flex justify-content-between align-items-center">
                <div className="booking-text d-flex flex-column justify-content-center">
                  <div className="booking-img d-flex align-items-center">
                    <img
                      src="../../../assets/images/Icon feather-settings.svg"
                      alt=""
                    />
                    <span className="heading ms-2">Service Provider</span>
                  </div>
                  <div className="sub-heading">Get/Edit Service Provider</div>
                </div>
              </div>
              <div className="all-configuration-styles">
                <div className="table-area mt-3">
                  <div className="table-responsive">
                    <table className="table">
                      <thead className="position-sticky top-0">
                        <tr>
                          <th scope="col">ID</th>
                          <th scope="col">Name</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!loading ? (
                          <tr className="d-flex justify-content-center">
                            <td>
                              <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                              </div>
                            </td>
                          </tr>
                        ) : (
                          sProvider.map((provider, index) => (
                            <tr key={index}>
                              <td>{provider.id}</td>
                              <td>{provider.name ? provider.name : "null"}</td>
                              <td>
                                <button
                                  className="activity-btns"
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal"
                                  onClick={() => {
                                    onEditHandler(provider);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faPencil} />
                                </button>
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Modal --> */}
      {console.log("In Modal---", pState)}
      <div
        className="modal fade"
        id="exampleModal"
        tabndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit {pState.id}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-4">
                  <div>
                    <h6>CardPayment</h6>
                  </div>
                  <div>
                    <select
                      className="form-select"
                      style={{ height: 40, width: 100 }}
                      aria-label="Default select example"
                      name="cardPayment"
                      value={pState.cardPayment}
                      onChange={onChangeHandler}
                    >
                      <option value={pState.cardPayment}>
                        {pState.cardPayment ? "true" : "false"}
                      </option>
                      <option value={pState.cardPayment ? false : true}>
                        {pState.cardPayment ? "false" : "true"}
                      </option>
                    </select>
                  </div>

                  <hr />
                  <div>
                    <h6>deliveryRideType</h6>
                  </div>
                  <div>
                    <select
                      style={{ height: 40, width: 100 }}
                      className="form-select"
                      aria-label="Default select example"
                      name="deliveryRideType"
                      value={pState.deliveryRideType}
                      onChange={onChangeHandler}
                    >
                      <option value={pState.deliveryRideType}>
                        {pState.deliveryRideType ? "true" : "false"}
                      </option>
                      <option value={pState.deliveryRideType ? false : true}>
                        {pState.deliveryRideType ? "false" : "true"}
                      </option>
                    </select>
                  </div>

                  <hr />
                  <div>
                    <h6>CashPayment</h6>
                  </div>
                  <div>
                    <select
                      style={{ height: 40, width: 100 }}
                      className="form-select"
                      aria-label="Default select example"
                      name="cashPayment"
                      value={pState.cashPayment}
                      onChange={onChangeHandler}
                    >
                      <option value={pState.cashPayment}>
                        {pState.cashPayment ? "true" : "false"}
                      </option>
                      <option value={pState.cashPayment}>
                        {pState.cashPayment ? "false" : "true"}
                      </option>
                    </select>
                  </div>

                  <hr />

                  <div>
                    <h6>Commision</h6>
                  </div>

                  <input
                    type="number"
                    placeholder="commission"
                    className="form-control"
                    name="commission"
                    value={pState.commission == null ? 0 : pState.commission}
                    onChange={onChangeHandler}
                  />
                </div>
                <div className="col-4">
                  <div>
                    <h6>priorityRideType</h6>
                  </div>
                  <div>
                    <select
                      style={{ height: 40, width: 100 }}
                      className="form-select"
                      aria-label="Default select example"
                      name="priorityRideType"
                      value={pState.priorityRideType}
                      onChange={onChangeHandler}
                    >
                      <option value={pState.priorityRideType}>
                        {pState.priorityRideType ? "true" : "false"}
                      </option>
                      <option value={pState.priorityRideType ? false : true}>
                        {pState.priorityRideType ? "false" : "true"}
                      </option>
                    </select>
                  </div>
                  <hr />

                  <div>
                    <h6>privateRideType</h6>
                  </div>
                  <div>
                    <select
                      style={{ height: 40, width: 100 }}
                      className="form-select"
                      aria-label="Default select example"
                      name="privateRideType"
                      value={pState.privateRideType == null ? false : true}
                      onChange={onChangeHandler}
                    >
                      <option
                        value={pState.privateRideType == null ? false : true}
                      >
                        {console.log("privateRideType", pState.privateRideType)}
                        {pState.privateRideType ? "true" : "false"}
                      </option>
                      <option value={pState.privateRideType ? false : true}>
                        {pState.privateRideType ? "false" : "true"}
                      </option>
                    </select>
                  </div>
                  <hr />

                  <div>
                    <h6>sharedRideType</h6>
                  </div>
                  <div>
                    <select
                      style={{ height: 40, width: 100 }}
                      className="form-select"
                      aria-label="Default select example"
                      name="sharedRideType"
                      value={pState.sharedRideType}
                      onChange={onChangeHandler}
                    >
                      <option value={pState.sharedRideType}>
                        {pState.sharedRideType ? "true" : "false"}
                      </option>
                      <option value={pState.sharedRideType ? false : true}>
                        {pState.sharedRideType ? "false" : "true"}
                      </option>
                    </select>
                  </div>

                  <hr />

                  <div>
                    <h6>MISC</h6>
                  </div>
                  <input
                    type="number"
                    placeholder="misc"
                    className="form-control"
                    name="misc"
                    value={pState.misc == null ? 0 : pState.misc}
                    onChange={onChangeHandler}
                  />
                </div>
                <div className="col-4">
                  <div>
                    <h6>wavePointsEnabled</h6>
                  </div>
                  <div>
                    <select
                      style={{ height: 40, width: 100 }}
                      className="form-select"
                      aria-label="Default select example"
                      name="waypointsEnabled"
                      value={pState.waypointsEnabled}
                      onChange={onChangeHandler}
                    >
                      <option value={pState.waypointsEnabled}>
                        {pState.waypointsEnabled ? "true" : "false"}
                      </option>
                      <option value={pState.waypointsEnabled ? false : true}>
                        {pState.waypointsEnabled ? "false" : "true"}
                      </option>
                    </select>
                  </div>
                  <hr />

                  <div>
                    <h6>enabled</h6>
                  </div>
                  <div>
                    <select
                      style={{ height: 40, width: 100 }}
                      className="form-select"
                      aria-label="Default select example"
                      name="enabled"
                      value={pState.enabled}
                      onChange={onChangeHandler}
                    >
                      <option value={pState.enabled}>
                        {pState.enabled ? "true" : "false"}
                      </option>
                      <option value={pState.enabled ? false : true}>
                        {pState.enabled ? "false" : "true"}
                      </option>
                    </select>
                  </div>

                  <hr />

                  <div>
                    <h6>WalletPayment</h6>
                  </div>
                  <div>
                    <select
                      style={{ height: 40, width: 100 }}
                      className="form-select"
                      aria-label="Default select example"
                      name="walletPayment"
                      value={pState.walletPayment}
                      onChange={onChangeHandler}
                    >
                      <option value={pState.walletPayment}>
                        {pState.walletPayment ? "true" : "false"}
                      </option>
                      <option value={pState.walletPayment ? false : true}>
                        {pState.walletPayment ? "false" : "true"}
                      </option>
                    </select>
                  </div>
                  <hr />

                  <div>
                    <h6>FixedCommission</h6>
                  </div>
                  <input
                    type="number"
                    placeholder="fixed_commission"
                    className="form-control"
                    name="fixedCommission"
                    value={
                      pState.fixedCommission == null
                        ? 0
                        : pState.fixedCommission
                    }
                    onChange={onChangeHandler}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-warning"
                onClick={onSaveChangesHandler}
                data-bs-dismiss="modal"
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceProvider;
