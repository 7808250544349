import React from 'react';
import logo from './logo.svg';
import './App.scss';
import AdminLogin from './components/admin-login/AdminLogin';
import AdminDashboard from './components/admin-dashboard/AdminDashboard';
import AdminBooking from './components/admin-booking/AdminBooking';
import NewBookingForm from './components/new-booking-form/NewBookingForm';
import FaresConfig from './components/fares-config/FaresConfig';
import FareCreateForm from './components/fare-create-form/FareCreateForm';
import FacilitiesDefinition from './components/facilities-definition/FacilitiesDefinition';
import LuggageDefinitionForm from './components/luggage-definition-form/LuggageDefinitionForm';
import LuggageDefinition from './components/luggage-definition/LuggageDefinition';
import PromotionDefinition from './components/promotion-definition/PromotionDefinition';
import PromotionDefinitionForm from './components/promotion-definition-form/PromotionDefinitionForm';
import RideTypeDefinition from './components/ridetype-definition/RideTypeDefinition';
import RideTypeDefinitionForm from './components/ridetype-definition-form/RideTypeDefinitionForm';
import ServiceArea from './components/service-area/ServiceArea';
import ShiftDefinition from './components/shift-definition/ShiftDefinition';
import ShiftDefinitionForm from './components/shift-definition-form/ShiftDefinitionForm';
import VehicleTypeDefinition from './components/vehicletype-definition/VehicleTypeDefinition';
import VehicleTypeDefinitionFrom from './components/vehicletype-definition-form/VehicleTypeDefinitionFrom';
import Integrations from './components/integrations/Integrations';
import IntegrationsForm from './components/integrations-form/IntegrationsForm';
import FacilitiesDefinitionForm from './components/facilities-definition-form/FacilitiesDefinitionForm';
import ServiceAreaForm from './components/service-area-form/ServiceAreaForm';
import SystemConfig from './components/system-config/SystemConfig';
import SystemConfigForm from './components/system-config-form/SystemConfigForm';
import RefundClaim from './components/refund-claim/RefundClaim';
import Telephony from './components/telephony/Telephony';
import ActivateUser from './components/activate-user/ActivateUser';
import { Route, Routes } from 'react-router-dom';
import  ProtectedRoute  from './components/protected-route/protected.route';
import SystemConfigEdit from './components/system-config-edit/SystemConfigEdit';
import AirportGeoFX from './components/airport-geofencing/AirportGeoFX';
import ServiceProvider from './components/service-provider/ServiceProvider';
import Registration from './components/analytics/registration/Registration';
import RideHistory from './components/analytics/ride-history/RideHistory';
import Payments from './components/analytics/payments/Payment';






function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<AdminLogin />} />
    
        
        <Route element={<ProtectedRoute />}>
                <Route element={<Payments />} path="/analytics/payments" />
                <Route element={<RideHistory />} path="/analytics/ride-history" />
                <Route element={<Registration />} path="/analytics/registration" />
                <Route element={<AdminBooking />} path="admin-booking" /> 
                <Route path="/service-provider" element={<ServiceProvider />} />
                <Route element={<AirportGeoFX />} path="/airport-geofencing"/>
                <Route element={<AdminDashboard/>} path="/admin-dashboard"/>
                <Route path='/fares-config' element={<FaresConfig />} />
                <Route path='/facilities-defination' element={<FacilitiesDefinition />} />
                <Route path='/luguage-defination' element={<LuggageDefinition />} />
                <Route path='/promotion-defination' element={<PromotionDefinition />} />
                <Route path='/ridetype-defination' element={<RideTypeDefinition />} />
                <Route path='/system-config' element={<SystemConfig />} />
                <Route path='/service-area' element={<ServiceArea />} />
                <Route path='/shift-defination' element={<ShiftDefinition />} />
                <Route path='/vehicletype-defination' element={<VehicleTypeDefinition />} />
                <Route path='/integrations' element={<Integrations />} />
                <Route path='/activate-user' element={<ActivateUser />} />
                <Route path='/telephony' element={<Telephony />} />
                <Route path='/system-config-form' element={<SystemConfigForm />} />
                <Route path='/system-config-edit' element={<SystemConfigEdit/>} />
               
        </Route>
       


        
       

        

        
        

      </Routes>
     
     
     {/* <AdminBooking /> */}
     {/* <NewBookingForm /> */}
     {/* <FaresConfig /> */}
     {/* <FareCreateForm /> */}
     {/* <FacilitiesDefinition /> */}
     {/* <LuggageDefinitionForm /> */}
     {/* <LuggageDefinition /> */}
     {/* <PromotionDefinition /> */}
     {/* <PromotionDefinitionForm /> */}
     {/* <RideTypeDefinition /> */}
     {/* <RideTypeDefinitionForm /> */}
     {/* <ServiceArea /> */}
     {/* <ShiftDefinition /> */}
     {/* <ShiftDefinitionForm /> */}
     {/* <VehicleTypeDefinition /> */}
     {/* <VehicleTypeDefinitionFrom /> */}
     {/* <Integrations /> */}
     {/* <IntegrationsForm /> */}
     {/* <FacilitiesDefinitionForm /> */}
     {/* <ServiceAreaForm /> */}
     {/* <SystemConfig /> */}
     {/* <SystemConfigForm /> */}
     {/* <RefundClaim /> */}
     {/* <Telephony /> */}
     {/* <ActivateUser /> */}
    </div>
  );
}

export default App;
