import { SET_AUTH, SET_AUTH_HEADER, SET_ANALYTICS_DROPDOWN, SET_EDIT_CHECK } from "./actions";

const initialstate = {
   auth:false,
   header:null,
   analyticsDropdown:false,
   editCheck:false
}

function userReducer(state = initialstate, action) {

    switch (action.type) {

        case  SET_AUTH:
            return {...state, auth: action.payload.auth}
        case  SET_AUTH_HEADER:
            return {...state, header: action.payload.header}
        case SET_ANALYTICS_DROPDOWN:
            return {...state, analyticsDropdown:action.payload.analyticsDropdown}  
        case SET_EDIT_CHECK:
            return {...state, editCheck:action.payload.editCheck}  
        default:
            return state
    }
}


export default userReducer;