import React from "react";
import axios from "axios";
import "./AirportGeofencing.scss";
import Footer from "../layout/footer/Footer";

import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { Col, Row, Table } from "react-bootstrap";
import DataTable from "react-data-table-component";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import Sidebar from "../shared/sidebar/Sidebar";
import Navbar from "../layout/navbar/Navbar";

class AirportGeoFX extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allGeofences: [],
      loadCheck: false,
      nameValidValue: "",
      postalValidValue: "",
      postalCodeValue: "",
      name: "",
      type: "AIRPORT",
      location: "",
      submitCheck: false,
      showModal: true,
      selectedItem: {},
      currentGfBeingEdited: null,
    };
    
  }
  selectedItemCopy = {};
  currentGfBeingEditedCopy = null;
  auth_token ="Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJwcm9kY3VzdG9tZXJAeW9wbWFpbC5jb20iLCJhdXRoIjoiUk9MRV9DVVNUT01FUixST0xFX1VTRVIiLCJleHAiOjE2NzIzMDUzNDZ9.83Xmmj3rHu3Af7GvRL2pbqU_NZRemyVwdAcOEBHnLKZc3UKbfWA-47dzbhuTCWZz2im8_0hvaqCq7QR7UEsKqg"
  // auth_token =
  //   "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJwcm9kY3VzdG9tZXJAeW9wbWFpbC5jb20iLCJhdXRoIjoiUk9MRV9DVVNUT01FUixST0xFX1VTRVIiLCJleHAiOjE2NjE1MDUyMDd9.Rvau6ZJwkQg0mxdAnNTe-vGZz0SkiUlRheUs91_yF5qPEG6fXQRQn4KT4cCMK4zEAPsYGOFqdsOPNWsxJKfT-A";

  namePattern = /^[A-Za-z -]+$/;
  tableHeaders = [
    {
      prop: "name",
      title: "Name",
      isFilterable: true,
    },
    {
      prop: "postalCode",
      title: "Postal Code",
      isSortable: true,
    },
  ];

  Type = ["AIRPORT", "RAIL WAY STATION", "MALL"];
  googleMapsPromise;
  destinations = [];

  polygon = null;
  map;
  polygonMarkers = [];

  ////////////////////////////

  showNotification(header, message, status = 200, timeout = 3000) {
    document.getElementById("notificaitonHeader").innerHTML = header;
    document.getElementById("notificationMessage").innerHTML = message;
    if (status == 200) {
      document.getElementById("generalNotification").style.backgroundColor =
        "#ffc112";
      if (
        document
          .getElementById("notificationIcon")
          .classList.contains("fa-question-circle")
      )
        document
          .getElementById("notificationIcon")
          .classList.remove("fa-question-circle");
      if (
        document
          .getElementById("notificationIcon")
          .classList.contains("fa-exclamation-triangle")
      )
        document
          .getElementById("notificationIcon")
          .classList.remove("fa-exclamation-triangle");

      document.getElementById("notificationIcon").classList.add("fa-check");
    } else if (status == 300) {
      document.getElementById("generalNotification").style.backgroundColor =
        "#8f6e32";
      if (
        document
          .getElementById("notificationIcon")
          .classList.contains("fa-check")
      )
        document
          .getElementById("notificationIcon")
          .classList.remove("fa-check");
      if (
        document
          .getElementById("notificationIcon")
          .classList.contains("fa-exclamation-triangle")
      )
        document
          .getElementById("notificationIcon")
          .classList.remove("fa-exclamation-triangle");

      document
        .getElementById("notificationIcon")
        .classList.add("fa-question-circle");
    } else {
      document.getElementById("generalNotification").style.backgroundColor =
        "#8f6e32";
      if (
        document
          .getElementById("notificationIcon")
          .classList.contains("fa-check")
      )
        document
          .getElementById("notificationIcon")
          .classList.remove("fa-check");
      if (
        document
          .getElementById("notificationIcon")
          .classList.contains("fa-question-circle")
      )
        document
          .getElementById("notificationIcon")
          .classList.remove("fa-question-circle");

      document.getElementById("generalNotification").style.backgroundColor =
        "red";
      document
        .getElementById("notificationIcon")
        .classList.add("fa-exclamation-triangle");
    }

    document.getElementById("generalNotification").classList.add("load");

    setTimeout(() => {
      this.hideNotification();
    }, timeout);
  }

  hideNotification() {
    document.getElementById("generalNotification").classList.remove("load");
  }

  /////////////////////////////

  componentDidMount() {
    console.log("componentDidMount")
    axios
    .get(`${process.env.REACT_APP_STG_HOST}?placeType.in=AIRPORT`, {
      headers: { Authorization: this.auth_token },
    })
    .then((response) => {
      this.setState({ ...this.state, allGeofences: response.data });
    });
    //initialize datatable
    //  "https://STGapi.cabscannerhq.com"
    $(document).ready(function () {
      setTimeout(function () {
        $("#example").DataTable();
      }, 1000);
    });
  

    this.getGoogleMaps().then((google) => {
      const uluru = { lat: -25.366, lng: 131.044 };
      this.map = new google.maps.Map(document.getElementById("map"), {
        center: { lat: 33.684422, lng: 73.047882 },
        zoom: 13,
      });

      var input = document.getElementById("searchInput");
      this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

      var autocomplete = new google.maps.places.Autocomplete(input);
      autocomplete.bindTo("bounds", this.map);

      var infowindow = new google.maps.InfoWindow();
      var marker = new google.maps.Marker({
        map: this.map,
        anchorPoint: new google.maps.Point(0, -29),
      });
      autocomplete.addListener("place_changed", () => {
        infowindow.close();
        marker.setVisible(false);
        var place = autocomplete.getPlace();
        if (!place.geometry) {
          window.alert("Autocomplete's returned place contains no geometry");
          return;
        }

        // // If the place has a geometry, then present it on a map.
        if (place.geometry.viewport) {
          this.map.fitBounds(place.geometry.viewport);
        } else {
          this.map.setCenter(place.geometry.location);
          this.map.setZoom(17);
        }
        marker.setIcon({
          url: place.icon,
          size: new google.maps.Size(71, 71),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(17, 34),
          scaledSize: new google.maps.Size(35, 35),
        });
        marker.setPosition(place.geometry.location);
        marker.setVisible(true);

        var address = "";
        if (place.address_components) {
          address = [
            (place.address_components[0] &&
              place.address_components[0].short_name) ||
              "",
            (place.address_components[1] &&
              place.address_components[1].short_name) ||
              "",
            (place.address_components[2] &&
              place.address_components[2].short_name) ||
              "",
          ].join(" ");
        }

        this.removeSelection();

        infowindow.setContent(
          "<div><strong>" + place.name + "</strong><br>" + address
        );
        infowindow.open(this.map, marker);

        let lat = place.geometry.location.lat();
        let long = place.geometry.location.lng();
        let bounds = new google.maps.LatLngBounds();
        this.map.setZoom(13);
      });
      this.initPolygon();
    });
  }

  onChangePostalCode = (e) => {
    this.setState({ ...this.state, postalCodeValue: e.target.value });
  };

  onChangeType = (e) => {
    this.setState({ ...this.state, type: e.target.value });
  };

  onChangeLocation = (e) => {
    this.setState({ ...this.state, location: e.target.value });
  };
  onChangeName = (e) => {
    this.setState({ ...this.state, name: e.target.value });

    console.log("on Name change called", this.state);
  };

  onBlurName = (e) => {
    if (this.namePattern.test(e.target.value)) {
      this.setState({ ...this.state, nameValidValue: "" });
    } else {
      if (e.target.value == "") {
        this.setState({ ...this.state, nameValidValue: "Name is required." });
      } else {
        this.setState({ ...this.state, nameValidValue: "invalid Name" });
      }
    }
  };

  getGoogleMaps() {
    if (!this.googleMapsPromise) {
      this.googleMapsPromise = new Promise((resolve) => {
        // Add a global handler for when the API finishes loading
        window.resolveGoogleMapsPromise = () => {
          // Resolve the promise
          resolve(window.google);

          // Tidy up
          delete window.resolveGoogleMapsPromise;
        };

        // Load the Google Maps API
        const script = document.createElement("script");
        console.log("called maps key")
        script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAP_KEY}&libraries=geometry,places&mod=staging&callback=resolveGoogleMapsPromise`;
        script.async = true;
        document.body.appendChild(script);
      
      });
    }

    // Return a promise for the Google Maps API
    return this.googleMapsPromise;
  }

  componentWillMount() {
    this.getGoogleMaps();
  }

  initPolygon() {
    this.destinations = [];
    this.generatePolygon(this.destinations);
  }

  generatePolygon(destinations1) {
    let polygonOptions = {
      path: destinations1,
      strokeColor: "#ff0000",
      filColor: "#00ff00",
    };
    this.polygon = new window.google.maps.Polygon(polygonOptions);
    // polygon.setMap(mapCopy);
    this.polygon.setMap(this.map);

    //  window.sessionStorage.setItem("polygon", JSON.stringify(polygon))

    if (destinations1.length) {
      this.map.setCenter(destinations1[0]);
      this.map.setZoom(13);
    }
    this.initMarkers();
  }

  addMarkerEventListener(marker) {
    console.log("marker on addEventListner above", marker);
    marker.addListener("click", (e) => {
      if (!window.confirm("Do you want to delete this position?")) return;

      this.deletePolygon();
      console.log("marker delete on addEventListner below", marker);
      setTimeout(() => {
        this.destinations = this.destinations.filter(
          (x) => x.lat !== e.latLng.lat() && x.lng !== e.latLng.lng()
        );
        console.log("destinations in SetTimeout", this.destinations);
        this.generatePolygon(this.destinations);
      }, 250);
    });
  }

  deletePolygon() {
    this.polygon && this.polygon.setMap(null);
    this.polygon = null;

    const clearDestinations = new window.google.maps.MVCArray();
    let polygonOptions = {
      path: clearDestinations,
      strokeColor: "#ff0000",
      filColor: "#00ff00",
    };
    this.polygon = new window.google.maps.Polygon(polygonOptions);
    this.polygon.setMap(this.map);
    // polygon.setMap(mapCopy);

    let count = 0;
    this.polygonMarkers.forEach((m) => {
      console.log("marker value", m);
      m.setMap(null);
    });

    this.polygonMarkers = [];
  }

  initMarkers() {
    //////////////////////////

    window.google.maps.event.addListener(this.map, "click", (e) => {
      // polygon = sessionStorage.getItem("polygon")
      // destinations = sessionStorage.getItem("destinations")

      let currentPath = this.polygon.getPath();
      // get the destination from itemObj if currentGFbeingEdited
      // destinations = JSON.parse(sessionStorage.getItem("destinations"));

      currentPath.push(e.latLng);
      this.destinations.push({
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      });

      const marker = new window.google.maps.Marker({
        position: e.latLng,
        map: this.map,
      });
      this.polygonMarkers.push(marker);

      this.addMarkerEventListener(marker);

      //  polygonMarkers.push(marker);
    });

    ////////////////////////////////
    this.destinations.forEach((d) => {
      const marker = new window.google.maps.Marker({
        position: d,
        map: this.map,
      });
      sessionStorage.setItem("destinations", JSON.stringify(this.destinations));

      this.addMarkerEventListener(marker);

      this.polygonMarkers.push(marker);
    });
  }

  onSubmit = async () => {
    if (
      this.state.postalCodeValue == "" ||
      this.state.name == "" ||
      this.state.nameValidValue == "invalid Name"
    ) {
      if (this.state.postalCodeValue == "")
        this.setState({
          ...this.state,
          postalValidValue: "Postal code is required",
        });
      if (this.state.name == "")
        this.setState({ ...this.state, nameValidValue: "Name is required" });
      if (this.state.nameValidValue == "invalid Name")
        this.setState({ ...this.state, nameValidValue: "invalid Name" });

      this.showNotification(
        "Missing information",
        "Please provide all the requirement information",
        300,
        5000
      );
    } else {
      let latlngArray = [];
      for (var a = 0; a < this.destinations.length; a++) {
        let object = {
          elevation: 0,
          latitude: this.destinations[a].lat,
          longitude: this.destinations[a].lng,
        };
        latlngArray.push(object);
      }

      // // Cordinates, which are first, has to be last for a closed loop polygon
      if (latlngArray.length) {
        if (
          latlngArray[latlngArray.length - 1].latitude !==
            latlngArray[0].latitude ||
          latlngArray[latlngArray.length - 1].longitude !==
            latlngArray[0].longitude
        ) {
          latlngArray.push(latlngArray[0]);
        }
      }

      const dataToSave = {
        placeType: this.state.type,
        name: this.state.name,
        boundaryCoordinates: latlngArray,
        postalCode: this.state.postalCodeValue,
        city: this.state.location,
      };
      let error = false;
      if (this.currentGfBeingEditedCopy) {
        dataToSave.id = this.currentGfBeingEditedCopy;
        await axios
          .put(`${process.env.REACT_APP_STG_HOST}`, dataToSave, {
            headers: { Authorization: this.auth_token },
          })
          .then((res) => {})
          .catch((err) => {
            if (err.response.status === 703) {
              this.showNotification(
                "Overlapping Geofence",
                "Please choose a different geofence. This geofence intersects with existing geofence",
                400,
                5000
              );
            } else {
              console.log("ghiaserror", err)
              this.showNotification("Failed", "Unable to add polygon", 400);
            }
          });
      } else {
        console.log("dataToSave", dataToSave)
        await axios
          .post(`${process.env.REACT_APP_STG_HOST}`, dataToSave, {
            headers: { Authorization: this.auth_token },
          })
          .catch((err) => {
            error = true;
            console.log("error status above", err.status);
            if (err.response.status === 703) {
              this.showNotification(
                "Overlapping Geofence",
                "Please choose a different geofence. This geofence intersects with existing geofence",
                400,
                5000
              );
            } else {
              if (err.response.status) {
                console.log("err status", err.status);
              }
              if (err.response) {
                console.log("err response", err.response);
              }
              if (err.request) {
                console.log("err reqest", err.request);
              }

              this.showNotification("Failed", "Unable to add polygon", 400);
            }
          });

        // rerender();
      }
      if (!error) {
        this.showNotification(
          "Geofence Saved",
          "Geofence is successfully saved"
        );
      }
      // submitCheck?setSubmitCheck(false):setSubmitCheck(true);
      //window.location.reload();
      axios
        .get(`${process.env.REACT_APP_STG_HOST}?placeType.in=AIRPORT`, {
          headers: { Authorization: this.auth_token },
        })
        .then((response) => {
          this.setState({ ...this.state, allGeofences: response.data });
        });
      this.removeSelection();
    }
  };

  openModal = (itemObj) => {
    // the below code line 1 should be removed just for testing, will be called on Edit modal,
    // and uncommment the commented line i-e setShowModal etc.

    //    setShowModal(true)
    //    console.log('show modal', showModal)
    window.sessionStorage.setItem("selectedItem", itemObj);
    this.setState({ ...this.state, selectedItem: itemObj });
    this.setState({ ...this.state, showModal: true });
    console.log("modal", this.state.showModal, itemObj);
    this.selectedItemCopy = itemObj;
  };

  closeModal = () => {
    this.setState({ ...this.state, showModal: false });
  };

  selectGf = (itemObj) => {
    // showModal = false;
    console.log("onEdit called", itemObj);
    this.deletePolygon();
    this.destinations = [];
    // // this.destinations = new google.maps.MVCArray();
    itemObj.boundaryCoordinates.forEach((bc, idx) => {
      this.destinations.push({
        lat: bc.latitude,
        lng: bc.longitude,
      });
    });
    this.setState({ ...this.state, currentGfBeingEdited: itemObj.id });
    this.currentGfBeingEditedCopy = itemObj.id;

    this.setState({
      ...this.state,
      type: this.Type[0],
      name: itemObj.name,
      postalCodeValue: itemObj.postalCode,
      location: "",
    });

    this.generatePolygon(this.destinations);
    document.getElementById("targetMap").scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  deleteGf = async (item) => {
    if (!window.confirm("Are you sure you want to delete this geofence?"))
      return;

    await axios
      .delete(`${process.env.REACT_APP_STG_HOST}/${item.id}`, {
        headers: { Authorization: this.auth_token },
      })
      .then((response) => {})
      .catch((err) => {});

    this.showNotification(
      "Geofence Deleted",
      "Geofence has been deleted successfully"
    );
    axios
      .get(`${process.env.REACT_APP_STG_HOST}?placeType.in=AIRPORT`, {
        headers: { Authorization: this.auth_token },
      })
      .then((response) => {
        this.setState({ ...this.state, allGeofences: response.data });
      });

    this.setState({ ...this.state, showModal: false });
    this.submitCheck
      ? this.setState({ ...this.state, submitCheck: false })
      : this.setState({ ...this.state, submitCheck: true });
    // window.location.reload();

    // getGeofences();
    this.removeSelection();
  };

  removeSelection = () => {
    document.getElementById("searchInput").value = "";
    this.currentGfBeingEditedCopy = null;
    this.setState({ ...this.state, currentGfBeingEdited: null });
    this.currentGfBeingEditedCopy = null;
    this.destinations = [];
    this.deletePolygon();
  };

  render() {
    console.log('geo',this.state.allGeofences)
    return (
      <>
        <Navbar />
        <div id="generalNotification" className="notification-popup">
          <em id="notificationIcon" className="fa"></em>&nbsp;
          <strong id="notificaitonHeader">Missing information</strong>
          <p id="notificationMessage">
            Please provide all the requirement information'
          </p>
        </div>
        <div className="outer-dashboard">
          <div className="row">
            <div className="col-md-12">
              <div className="form-outer-area">
                <div className="row" id="targetMap">
                  <div className="col-lg-9.col-md-12 col-sm-12">
                    <div className="top-form">
                      <form>
                        <div className="row">
                          <div className="col-md-4 form-group">
                            <select
                              className="form-control select-option"
                              value={this.state.type}
                              onChange={this.onChangeType}
                            >
                              <option disabled>Choose your Type</option>
                              {this.Type.map((type, index) => (
                                <option key={index} value={this.state.type}>
                                  {this.state.type}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="col-md-4 form-group validate">
                            <input
                              type="text"
                              name="name"
                              id=""
                              className="form-control required app-inputfield"
                              placeholder="Name"
                              required
                              value={this.state.name}
                              onChange={this.onChangeName}
                              onBlur={this.onBlurName}
                            />
                            <span
                              style={{
                                color: "#eb4b4b",
                                fontWeight: "normal",
                                fontSize: 12,
                              }}
                            >
                              {this.state.nameValidValue}
                            </span>
                          </div>
                          <div className="col-md-4 form-group validate">
                            <input
                              type="text"
                              value={this.state.postalCodeValue}
                              name=""
                              id=""
                              className="form-control app-inputfield required"
                              placeholder="Postal Code"
                              onChange={this.onChangePostalCode}
                            />
                            <span
                              style={{
                                color: "#eb4b4b",
                                fontWeight: "normal",
                                fontSize: 12,
                              }}
                            >
                              {this.state.postalValidValue}
                            </span>
                          </div>
                        </div>
                        <div className="btn-area py-3 pr-2 d-flex justify-content-end">
                          <button
                            type="button"
                            className="dark-btn mr-2"
                            onClick={this.onSubmit}
                          >
                            Save
                          </button>
                          <button
                            type="button"
                            className="cancel-btn ml-2 rounded-0"
                            onClick={this.removeSelection}
                          >
                            Reset
                          </button>
                        </div>
                        <div className="map-area">
                          <div id="map"></div>
                          <div className="enter-location">
                            <div className="form-group">
                              <input
                                style={{ zIndex: 2 }}
                                id="searchInput"
                                className="form-control inputfield-location-map"
                                type="text"
                                placeholder="Enter a location"
                                value={this.state.location}
                                onChange={this.onChangeLocation}
                              />
                            </div>
                          </div>
                        </div>
                        

                        <div className="table-area">
                          <div className="table-responsive">
                            <table id="example" className="table table-hover">
                              <thead>
                                <tr>
                                  <th>Name</th>
                                  <th>Postal Code</th>
                                </tr>
                              </thead>
                              <tbody>
                                
                                { this.state.allGeofences.map((item, index) => (
                                  <tr
                                    key={index}
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModal"
                                    onClick={() => {
                                      this.openModal(item);
                                    }}
                                  >
                                    <td>{item.name}</td>
                                    <td>{item.postalCode}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>

                  <div></div>
                </div>

                {/* <!-- Modal --> */}
                {true ? (
                  <div>
                    <div
                      className="modal connecting-modal"
                      id="exampleModal"
                      tabIndex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-centered m-auto connecting-dialog">
                        <div className="modal-content connecting-content">
                          <div className="close-btn py-2 px-3">
                            <button
                              type="button"
                              className="close"
                              onClick={this.closeModal}
                              aria-label="Close"
                              data-bs-dismiss="modal"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body text-center connecting-body">
                            <h5>What do you want to do ?</h5>
                            <div className="btns-div">
                              <button
                                onClick={() => {
                                  console.log("onEit", this.selectedItemCopy);
                                  this.selectGf(this.selectedItemCopy);
                                }}
                                className="change-btn mr-2"
                                data-dismiss="modal"
                                type="button"
                                data-bs-dismiss="modal"
                              >
                                Edit
                              </button>
                              <button
                                onClick={() => {
                                  this.deleteGf(this.selectedItemCopy);
                                }}
                                className="cancel-btn ml-2"
                                type="button"
                                data-bs-dismiss="modal"
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AirportGeoFX;
