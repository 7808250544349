import {
  faAngleDown,
  faBell,
  faBars,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Sidebar from "../../shared/sidebar/Sidebar";
import React from "react";
import "./Navbar.scss";
import { useNavigate } from "react-router-dom";

function Navbar() {

  const navigate = useNavigate()
  return (
    <>
      <nav className="outer-navbar sticky-top">
        <div className="inner-nav">
          <div className="offcanvasOuter">
            <div
              className="offcanvasIcon"
              data-bs-toggle="offcanvas"
              href="#offcanvasExample"
              role="button"
              aria-controls="offcanvasExample"
            >
              <FontAwesomeIcon icon={faBars} />
              <span></span>
            </div>

            <div
              className="offcanvas offcanvas-start"
              tabIndex="-1"
              id="offcanvasExample"
              aria-labelledby="offcanvasExampleLabel"
            >
              <div className="offcanvas-header">
                <div className="brand-logo-area">
                  <a href="#" className="brand-img">
                    <img
                      src="../../../../assets/images/cs-header-logo.svg"
                      alt="cabscanner logo"
                    />
                  </a>
                </div>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div className="offcanvas-body pt-0">
                <Sidebar />
              </div>
            </div>
          </div>
          {/* <div className="brand-logo-area">
            <a href="#" className="brand-img">
              <img
                src="../../../../assets/images/cs-header-logo.svg"
                alt="cabscanner logo"
              />
            </a>
          </div> */}
          <div className="profile-notify-area">
            <div className="notification me-3">
              <span>
                <FontAwesomeIcon icon={faBell} />
              </span>
            </div>
            <div className="admin-profile ms-3">
              <div className="profile-img d-none">
                <img
                  src="../../../../assets/images/avatar.png"
                  alt="admin profile image"
                />
              </div>
              <span className="logout-text" onClick={() => navigate("/")}>Logout</span>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
