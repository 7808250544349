import { Outlet, Navigate } from 'react-router-dom'
// import auth from '../utils/auth'
import { useSelector, useDispatch } from 'react-redux'
import { setAuth } from '../../redux/actions'

const PrivateRoutes = () => {
    // let auth = {'token':true}
    // const{auth}= useSelector((state ) => state.reducer)
    const auth =  localStorage.getItem("auth")
    console.log('auth ', auth)

    return(
        auth ? <Outlet/> : <Navigate to="/"/>
    )
}

export default PrivateRoutes