import {
  faEye,
  faPencil,
  faTrashCan,
  faArrowsRotate,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import Sidebar from "../shared/sidebar/Sidebar";
import axios from "axios";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../layout/navbar/Navbar";
import { setEditCheck } from "../../redux/actions";
import { useDispatch } from "react-redux";

function SystemConfigEdit() {
  
  const dispatch = useDispatch()
  const { state } = useLocation();
  const { obj } = state;
  const{editCheck}= useSelector((state ) => state.reducer)
  const header = localStorage.getItem("header");

  const [updateSystemConfigObj, setUpdateSystemConfigObj] = useState({
    id: "",
    name: "",
    value: "",
    valueType: "",
  });
  const [checkInputClick, setCheckInputClick] = useState(false);
  const navigate = useNavigate();

  const [systemConfigData, setSystemConfigData] = useState([
    { name: "", value: "", valueType: "" },
  ]);
  useEffect(() => {
    // axios.get("https://stgapi.cabscannerhq.com/api/system-configuration-keys", { headers: { 'Authorization': header } })
    // .then(response=>{
    //   setSystemConfigData(response.data)
    // })
    // .catch(error=>{
    //   console.log('get error', error)
    // })
    setSystemConfigData([obj]);
  }, []);

  const onViewHandler = () => {
    navigate("/system-config");
  };

  const onSaveHandler = async () => {
     await axios
      .put(
        `${process.env.REACT_APP_SYSTEM_CONFIG_EDIT_API}`,
        updateSystemConfigObj,
        { headers: { Authorization: header } }
      )
      .then((response) => {
        console.log("updated called", response);
      })
      .catch((error) => {
        console.log("get error", error);
      });
     await dispatch(setEditCheck(editCheck?false:true))
    navigate("/system-config");
  };

  const onChangeSelect = (SystemConfigObjPassed, e) => {
    var firstWordUrl;
    var lastWordUrl;

    var urlValidateCheck = true;
      const url_value = e.target.value;
      // const systemConfigDataCopy = [...systemConfigData]
      // const objIndex =  systemConfigDataCopy.indexOf(SystemConfigObjPassed)
      // systemConfigDataCopy[objIndex].value = e.target.value
      //
      // SystemConfigObjPassed.value = e.target.value;
      //  setSystemConfigData(systemConfigDataCopy)
      // setUpdateSystemConfigObj(SystemConfigObjPassed)

      if (
        SystemConfigObjPassed.name == "base_url" ||
        SystemConfigObjPassed.name == "resource_url"
      ) {
        const url_split = url_value.split(":");
        const url_endsplit = url_split[1].split(".");
        const lastword = url_endsplit[url_endsplit.length - 1];
        lastWordUrl = lastword;
        firstWordUrl = url_split[0];
        console.log(firstWordUrl, lastWordUrl);

        if (lastWordUrl == "com") {
          if (firstWordUrl == "http" || firstWordUrl == "https") {
            const systemConfigDataCopy = [...systemConfigData];
            const objIndex = systemConfigDataCopy.indexOf(
              SystemConfigObjPassed
            );
            systemConfigDataCopy[objIndex].value = e.target.value;
            SystemConfigObjPassed.value = e.target.value;
            setSystemConfigData(systemConfigDataCopy);
            setUpdateSystemConfigObj(SystemConfigObjPassed);
          } else {
            alert("incorrect url");
          }
        } else {
          alert("incorrect url");
        }
      } else {
        const systemConfigDataCopy = [...systemConfigData];
        const objIndex = systemConfigDataCopy.indexOf(SystemConfigObjPassed);
        systemConfigDataCopy[objIndex].value = e.target.value;
        SystemConfigObjPassed.value = e.target.value;
        setSystemConfigData(systemConfigDataCopy);
        setUpdateSystemConfigObj(SystemConfigObjPassed);
      }
  }

  const onInputChangeHandler = (SystemConfigObjPassed, e) => {
    // setCheckInputClick(true)
    var firstWordUrl;
    var lastWordUrl;
    let copyVal = e.target.value;
    var isNumber = Number(copyVal);
    if(!e.target.value){
        copyVal = '';
        isNumber = true;

    }

    console.log("value", e.target.value, SystemConfigObjPassed,"isNumber", isNumber)

    if (!isNumber && SystemConfigObjPassed.valueType != "STRING") {
      alert("Sorry! cannot enter unmatched type");
    } else {
      var urlValidateCheck = true;
      const url_value = e.target.value;
      // const systemConfigDataCopy = [...systemConfigData]
      // const objIndex =  systemConfigDataCopy.indexOf(SystemConfigObjPassed)
      // systemConfigDataCopy[objIndex].value = e.target.value
      //
      // SystemConfigObjPassed.value = e.target.value;
      //  setSystemConfigData(systemConfigDataCopy)
      // setUpdateSystemConfigObj(SystemConfigObjPassed)

      if (
        SystemConfigObjPassed.name == "base_url" ||
        SystemConfigObjPassed.name == "resource_url"
      ) {
        const url_split = url_value.split(":");
        const url_endsplit = url_split[1].split(".");
        const lastword = url_endsplit[url_endsplit.length - 1];
        lastWordUrl = lastword;
        firstWordUrl = url_split[0];
        console.log(firstWordUrl, lastWordUrl);

        if (lastWordUrl == "com") {
          if (firstWordUrl == "http" || firstWordUrl == "https") {
            const systemConfigDataCopy = [...systemConfigData];
            const objIndex = systemConfigDataCopy.indexOf(
              SystemConfigObjPassed
            );
            systemConfigDataCopy[objIndex].value = e.target.value;
            SystemConfigObjPassed.value = e.target.value;
            setSystemConfigData(systemConfigDataCopy);
            setUpdateSystemConfigObj(SystemConfigObjPassed);
          } else {
            alert("incorrect url");
          }
        } else {
          alert("incorrect url");
        }
      } else {
        const systemConfigDataCopy = [...systemConfigData];
        const objIndex = systemConfigDataCopy.indexOf(SystemConfigObjPassed);
        systemConfigDataCopy[objIndex].value = copyVal;
        SystemConfigObjPassed.value = copyVal;
        setSystemConfigData(systemConfigDataCopy);
        setUpdateSystemConfigObj(SystemConfigObjPassed);
      }
    }
  };
   console.log("obj")
  return (
    <>
      <Navbar />
      <div className="outer-dashboard">
        <div className="row">
          <div className="col-md-12">
            <div className="right-dashboard">
              <div className="bookings-header d-flex justify-content-between align-items-center">
                <div className="booking-text d-flex flex-column justify-content-center">
                  <div className="booking-img d-flex align-items-center">
                    <img
                      src="../../../assets/images/Icon feather-settings.svg"
                      alt=""
                    />
                    <span className="heading ms-2">
                      System Configuration Edit
                    </span>
                  </div>
                  <div className="sub-heading">
                    Create/Edit New System Configurations
                  </div>
                </div>
                <div className="add-booking-btn">
                  <button className="primary-radius-btn" type="button">
                    + NEW CONFIGURATION
                  </button>
                </div>
              </div>
              <div className="all-configuration-styles">
                <div className="section-activity-area">
                  <div className="btns">
                    <button className="activity-btns" onClick={onViewHandler}>
                      <FontAwesomeIcon icon={faEye} /> View
                    </button>
                    <button className="activity-btns">
                      <FontAwesomeIcon icon={faPencil} /> Edit
                    </button>
                    <button className="activity-btns">
                      <FontAwesomeIcon icon={faTrashCan} /> Delete
                    </button>
                    <button className="activity-btns">
                      <FontAwesomeIcon icon={faArrowsRotate} /> Refresh
                    </button>
                  </div>
                  <div className="search-area d-none">
                    <form action="">
                      <div className="form-group">
                        <input
                          type="search"
                          className="form-control app-inputfield"
                          name=""
                          id=""
                          placeholder="search here..."
                        />
                      </div>
                    </form>
                  </div>
                </div>
                <div className="table-area">
                  <div className="table-responsive">
                    <table className="table">
                      <thead className="position-sticky top-0">
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Value</th>
                          <th scope="col">Save</th>
                        </tr>
                      </thead>
                      <tbody>
                        {systemConfigData.map((SystemConfigObj, index) => (
                          <tr key={index}>
                            <td>{SystemConfigObj.name}</td>
                            <td>
                              {
                                SystemConfigObj.valueType != "BOOLEAN" ? (
                                  <input
                                    type="text"
                                    value={SystemConfigObj.value}
                                    onChange={(e) =>
                                      onInputChangeHandler(SystemConfigObj, e)
                                    }
                                  />
                                ) : (
                                  <select
                                    value={SystemConfigObj.value}
                                    onChange={(e) =>
                                      onChangeSelect(SystemConfigObj, e)
                                    }
                                  >
                                    <option value="true">true</option>
                                    <option value="false">false</option>
                                  </select>
                                )
                                // <input type="text" value={SystemConfigObj.value} onChange={(e)=>onInputChangeHandler(SystemConfigObj,e)} />
                              }
                            </td>
                            <td>
                              <button
                                className="activity-btns"
                                onClick={() => onSaveHandler(SystemConfigObj)}
                              >
                                <FontAwesomeIcon icon={faPencil} /> Save
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default SystemConfigEdit;
